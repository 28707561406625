import { render, staticRenderFns } from "./FeaturedArticleLoading.vue?vue&type=template&id=4d3d4865&scoped=true&"
import script from "./FeaturedArticleLoading.vue?vue&type=script&lang=js&"
export * from "./FeaturedArticleLoading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d3d4865",
  null
  
)

export default component.exports