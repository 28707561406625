var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"featured-article-loaded"},[_c('router-link',{attrs:{"to":{
			name: 'Article',
			params: {
				slug: _vm.articleSlug,
				title: _vm.articleTitle,
				authorName: _vm.authorName,
				authorUsername: _vm.authorUsername,
			},
		}}},[_c('TheArticleTitle',{attrs:{"articleTitle":_vm.articleTitle,"authorName":_vm.authorName,"authorUsername":_vm.authorUsername}})],1),_c('div',{staticClass:"container w-full relative mx-auto"},[_c('router-link',{attrs:{"to":{
				name: 'Article',
				params: {
					slug: _vm.articleSlug,
					title: _vm.articleTitle,
					authorName: _vm.authorName,
					authorUsername: _vm.authorUsername,
				},
			}}},[_c('img',{staticClass:"max-h-40 md:max-h-96 w-full object-cover mt-3",attrs:{"src":_vm.articleImage,"alt":_vm.articleTitle}}),_c('p',{staticClass:"text-md text-justify w-full"},[_vm._v(" "+_vm._s(_vm.articleTeaser)+" ")])])],1),_c('div',{staticClass:"flex mt-2 md-2"},[_c('div',{staticClass:"text-sm flex-none"},[_vm._v(" Published on: "),_c('a',{staticClass:"date thick-underline"},[_vm._v(_vm._s(_vm.published))])]),_c('span',{staticClass:"flex-grow"})]),_c('ul',{staticClass:"hashtags"},_vm._l((_vm.tags),function(tag){return _c('li',{key:tag,staticClass:"hashtag"},[_c('router-link',{staticClass:"thick-underline",attrs:{"to":{ name: 'tag', params: { tag: tag } }}},[_vm._v(" "+_vm._s(tag)+" ")])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }