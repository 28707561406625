<template>
	<article class="w-auto h-auto article animate-pulse">
		<div
			class="h-10 w-3/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="h-32 md:h-56 w-full opacity-30 my-3 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-4/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-9/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-11/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-3 h-4 w-2/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
	</article>
</template>

<script>
export default {
	name: "ArticleBlockLoading",
};
</script>

<style scoped></style>
