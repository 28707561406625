var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"w-auto h-auto article"},[_c('h2',{staticClass:"text-3xl"},[_c('router-link',{attrs:{"to":{
				name: 'Article',
				params: {
					slug: _vm.article.slug,
					title: _vm.article.title,
					authorName: _vm.article.author.authorName,
					authorUsername: _vm.article.author.authorUsername,
				},
			}}},[_vm._v(" "+_vm._s(_vm.article.title)+" ")]),_c('span',{staticClass:"text-sm inline"},[_vm._v(" by "),_c('router-link',{staticClass:"author thick-underline cursor-pointer",attrs:{"to":{
					name: 'Authors',
					params: {
						authorName: _vm.article.author.authorName,
						authorUsername: _vm.article.author.authorUsername,
					},
				}}},[_vm._v(_vm._s(_vm.article.author.authorName))])],1)],1),_c('router-link',{attrs:{"to":{
			name: 'Article',
			params: {
				slug: _vm.article.slug,
				title: _vm.article.title,
				authorName: _vm.article.author.authorName,
				authorUsername: _vm.article.author.authorUsername,
			},
		}}},[_c('img',{staticClass:"max-h-32 md:max-h-56 w-full object-cover mt-3",attrs:{"src":_vm.article.img.imgSrc,"alt":_vm.article.title}}),_c('p',{staticClass:"mt-3 text-md text-justify max-w-prose"},[_vm._v(" "+_vm._s(_vm.article.excerpt)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-none w-3/4"},[_c('ul',{staticClass:"hashtags"},_vm._l((_vm.article.tags),function(tag){return _c('li',{key:tag,staticClass:"hashtag"},[_c('router-link',{staticClass:"thick-underline",attrs:{"to":{ name: 'tag', params: { tag: tag } }}},[_vm._v(" "+_vm._s(tag)+" ")])],1)}),0)]),_c('span',{staticClass:"flex-grow"}),_c('span',{staticClass:"view-count flex-none text-sm font-normal"},[_vm._v(" "+_vm._s(_vm.article.viewsCount)+" "),_c('img',{staticClass:"h-4 mb-1 inline dark:hidden",attrs:{"src":require("./../assets/icons/eye.svg"),"alt":"Eye"}}),_c('img',{staticClass:"dark:inline h-4 mb-1 hidden",attrs:{"src":require("./../assets/icons/eye-white.svg"),"alt":"Eye"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }