<template>
	<article class="w-auto h-auto article article-featured">
		<FeaturedArticleLoading v-show="!articleLoaded" />
		<FeaturedArticleLoaded
			v-cloak
			v-if="articleLoaded"
			:articleTitle="articleTitle"
			:authorName="authorName"
			:authorUsername="authorUsername"
			:articleTeaser="articleTeaser"
			:articleSlug="articleSlug"
			:published="published"
			:tags="tags"
			:articleImage="articleImage"
		/>
	</article>
</template>

<script>
//packages import
import axios from "axios";

//components import
import FeaturedArticleLoading from "@/components/FeaturedArticleLoading";
import FeaturedArticleLoaded from "@/components/FeaturedArticleLoaded";

export default {
	name: "FeaturedArticle",
	components: {
		FeaturedArticleLoading,
		FeaturedArticleLoaded,
	},
	data() {
		return {
			articleTitle: "",
			authorName: "",
			authorUsername: "",
			articleTeaser: "",
			articleSlug: "",
			published: "",
			tags: [],
			articleImage: "",
			articleLoaded: false,
		};
	},
	mounted() {
		this.fetchFeaturedArticle();
	},
	methods: {
		fetchFeaturedArticle() {
			let baseUrl = "https://shaoor-api.azurewebsites.net/api/";
			let featuredEndpoint = `${baseUrl}featured`;
			axios.get(featuredEndpoint).then(res => {
				this.articleTitle = res.data.articleTitle;
				this.authorName = res.data.authorName;
				this.authorUsername = res.data.authorUsername;
				this.articleTeaser = res.data.articleTeaser;
				this.articleSlug = res.data.articleSlug;
				this.published = res.data.published;
				this.tags = res.data.tags;
				this.articleImage = res.data.articleImage;
				this.articleLoaded = true;
			});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
[v-cloak] {
	display: none;
}
</style>
