import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		props: true,
	},
	{
		path: "/article/:slug",
		name: "Article",
		component: () =>
			import(/* webpackChunkName: "article" */ "../views/Article.vue"),
		props: true,
	},
	{
		path: "/authors/:authorUsername",
		name: "Authors",
		component: () =>
			import(/* webpackChunkName: "authors" */ "../views/Author.vue"),
		props: true,
	},
	{
		path: "/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue"),
		props: true,
	},
	{
		path: "/tnc",
		name: "TnC",
		component: () => import(/* webpackChunkName: "terms" */ "../views/TnC.vue"),
		props: true,
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: () =>
			import(/* webpackChunkName: "terms" */ "../views/Privacy.vue"),
		props: true,
	},
	{
		path: "/tag/:tag",
		name: "tag",
		component: () => import(/* webpackChunkName: "tag" */ "../views/Tag.vue"),
		props: true,
	},
	{
		path: "/404",
		alias: "*",
		name: "NotFound",
		component: () =>
			import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
	},
];

const router = new VueRouter({
	mode: "hash",
	routes,
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	navigationFallback: {
		rewrite: "./index.html",
		exclude: ["./assets/*.{png,jpg,gif}", "./css/*"],
	},
});

router.beforeEach((to, from, next) => {
	let documentTitle = process.env.VUE_APP_NAME;
	documentTitle += to.params.title ? ` - ${to.params.title}` : ` - ${to.name}`;
	document.title = documentTitle;
	next();
});

export default router;
