<template>
	<div class="block">
		<br />
		<router-link
			class="thick-underline"
			:to="{
				name: 'Article',
				params: {
					slug: slug,
					title: title,
					authorName: authorName,
					authorUsername: authorUsername,
				},
			}"
		>
			{{ title }}
		</router-link>
	</div>
</template>
<script>
export default {
	name: "LatestArticles",
	props: {
		title: String,
		slug: String,
		authorName: String,
		authorUsername: String,
	},
};
</script>
<style scoped></style>
