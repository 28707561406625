<template>
	<div id="app">
		<div id="nav">
			<Header />
			<br />
			<router-view :key="$route.path" />
			<Footer />
		</div>
	</div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
	name: "app",
	components: { Header, Footer },
};
</script>

<style></style>
