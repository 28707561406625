var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block"},[_c('br'),_c('router-link',{staticClass:"thick-underline",attrs:{"to":{
			name: 'Article',
			params: {
				slug: _vm.slug,
				title: _vm.title,
				authorName: _vm.authorName,
				authorUsername: _vm.authorUsername,
			},
		}}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }