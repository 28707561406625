<template>
	<div class="home mx-8 w-auto">
		<small class="text-xl font-mono font-bold text-opacity-40">
			Featured
		</small>
		<div class="grid grid-col-1">
			<FeaturedArticle />
			<div class="my-4 md:grid md:grid-cols-8 md:gap-8">
				<div class="md:col-span-1"></div>
				<div class="md:col-span-4">
					<ArticleBlockLoading v-show="!articlesLoaded" />
					<ArticleBlock
						v-for="article in articles"
						v-show="articlesLoaded"
						v-cloak
						:article="article"
						:key="article._id"
					/>
				</div>
				<div class="mt-8 md:col-span-2">
					<h4 class="text-lg uppercase font-bold">Top Tags</h4>
					<TenTags />
					<div class="block no-phone">
						<h4 class="block text-lg uppercase font-bold mt-4">
							Latest News
						</h4>
						<div class="animate-pulse" v-show="!articlesLoaded">
							<div
								class="block mt-2 h-4 w-10/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
							></div>
							<div
								class="block mt-2 h-4 w-5/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
							></div>
						</div>
						<LatestArticles
							v-show="articlesLoaded"
							v-for="article in articles"
							:key="article._id"
							:title="article.title"
							:slug="article.slug"
							:authorName="article.author.authorName"
							:authorUsername="article.author.authorUsername"
						/>
					</div>
					<div class="block no-phone">
						<h4 class="block text-lg uppercase font-bold mt-4">
							Categories
						</h4>
						<!-- TODO: add categories component </ul> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//packages import
import axios from "axios";

//components import
import FeaturedArticle from "./../components/FeaturedArticle.vue";
import ArticleBlock from "./../components/ArticleBlock.vue";
import ArticleBlockLoading from "./../components/ArticleBlockLoading.vue";
import TenTags from "./../components/TenTags.vue";
import LatestArticles from "../components/LatestArticles.vue";

export default {
	name: "Home",
	data() {
		return {
			articles: [],
			articlesLoaded: false,
		};
	},
	components: {
		FeaturedArticle,
		ArticleBlock,
		ArticleBlockLoading,
		TenTags,
		LatestArticles,
	},
	created() {
		this.fetchAllArticles();
	},
	methods: {
		fetchAllArticles() {
			let baseUrl = "https://shaoor-api.azurewebsites.net/api/articles";
			let allArticles = `${baseUrl}`;
			axios.get(allArticles).then(res => {
				this.articles = res.data;
				this.articlesLoaded = true;
			});
		},
	},
};
</script>

<style scoped>
[v-cloak] {
	display: none;
}
</style>
