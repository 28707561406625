<template>
	<div class="featured-article-loading">
		<div class="animate-pulse">
			<div
				class="h-10 w-3/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
			></div>
		</div>
		<div class="animate-pulse">
			<div
				class="h-40 md:h-96 w-full mt-3 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
			></div>
			<div
				class="mt-2 h-6 w-4/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
			></div>
		</div>
		<div class="animate-pulse">
			<div
				class="h-4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
			></div>
		</div>
	</div>
</template>
<script>
export default {
	name: "FeaturedArticleLoading",
};
</script>
<style scoped></style>
