<template>
	<ul class="hashtags">
		<li
			class="hashtag"
			v-for="tag in tenTags"
			v-cloak
			v-show="tagsLoaded"
			:key="tag"
		>
			<router-link
				:to="{ name: 'tag', params: { tag: tag } }"
				class="thick-underline"
				>{{ tag }}</router-link
			>
		</li>
		<div class="animate-pulse" v-show="!tagsLoaded">
			<div
				class="inline-block mt-2 h-4 w-2/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mx-1"
			></div>
			<div
				class="inline-block mt-2 h-4 w-3/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mx-1"
			></div>
			<div
				class="inline-block mt-2 h-4 w-4/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mx-1"
			></div>
			<div
				class="inline-block mt-2 h-4 w-2/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mx-1"
			></div>
			<div
				class="inline-block mt-2 h-4 w-1/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mx-1"
			></div>
			<div
				class="inline-block mt-2 h-4 w-3/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mx-1"
			></div>
			<div
				class="inline-block mt-2 h-4 w-2/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mx-1"
			></div>
		</div>
	</ul>
</template>

<script>
import axios from "axios";

export default {
	name: "TenTags",
	data() {
		return {
			tenTags: [],
			tagsLoaded: false,
		};
	},
	mounted() {
		this.fetchTenTags();
	},
	methods: {
		fetchTenTags() {
			let baseUrl = "https://shaoor-api.azurewebsites.net/api/";
			let tenTagsEndpoint = `${baseUrl}tags`;
			axios.get(tenTagsEndpoint).then(res => {
				this.tenTags = res.data;
				this.tagsLoaded = true;
			});
		},
	},
};
</script>

<style scoped></style>
