<template>
	<div class="featured-article-loaded">
		<router-link
			:to="{
				name: 'Article',
				params: {
					slug: articleSlug,
					title: articleTitle,
					authorName: authorName,
					authorUsername: authorUsername,
				},
			}"
		>
			<TheArticleTitle
				:articleTitle="articleTitle"
				:authorName="authorName"
				:authorUsername="authorUsername"
			/>
		</router-link>
		<div class="container w-full relative mx-auto">
			<router-link
				:to="{
					name: 'Article',
					params: {
						slug: articleSlug,
						title: articleTitle,
						authorName: authorName,
						authorUsername: authorUsername,
					},
				}"
			>
				<img
					class="max-h-40 md:max-h-96 w-full object-cover mt-3"
					:src="articleImage"
					:alt="articleTitle"
				/>
				<p class="text-md text-justify w-full">
					{{ articleTeaser }}
				</p></router-link
			>
		</div>

		<div class="flex mt-2 md-2">
			<div class="text-sm flex-none" v-cloak>
				Published on:
				<a class="date thick-underline">{{ published }}</a>
			</div>

			<span class="flex-grow"></span>
		</div>
		<ul class="hashtags">
			<li class="hashtag" v-for="tag in tags" :key="tag">
				<router-link
					:to="{ name: 'tag', params: { tag: tag } }"
					class="thick-underline"
				>
					{{ tag }}
				</router-link>
			</li>
		</ul>
	</div>
</template>
<script>
//components import
import TheArticleTitle from "./../components/TheArticleTitle.vue";

export default {
	name: "FeaturedArticleLoaded",
	components: { TheArticleTitle },
	props: {
		articleTitle: String,
		authorName: String,
		authorUsername: String,
		articleTeaser: String,
		articleSlug: String,
		published: String,
		tags: Array,
		articleImage: String,
	},
};
</script>
<style scoped></style>
