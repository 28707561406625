<template>
	<article class="w-auto h-auto article">
		<h2 class="text-3xl">
			<router-link
				:to="{
					name: 'Article',
					params: {
						slug: article.slug,
						title: article.title,
						authorName: article.author.authorName,
						authorUsername: article.author.authorUsername,
					},
				}"
			>
				{{ article.title }}
			</router-link>
			<span class="text-sm inline">
				by
				<router-link
					:to="{
						name: 'Authors',
						params: {
							authorName: article.author.authorName,
							authorUsername: article.author.authorUsername,
						},
					}"
					class="author thick-underline cursor-pointer"
					>{{ article.author.authorName }}</router-link
				>
			</span>
		</h2>
		<router-link
			:to="{
				name: 'Article',
				params: {
					slug: article.slug,
					title: article.title,
					authorName: article.author.authorName,
					authorUsername: article.author.authorUsername,
				},
			}"
		>
			<img
				class="max-h-32 md:max-h-56 w-full object-cover mt-3"
				:src="article.img.imgSrc"
				:alt="article.title"
			/>
			<p class="mt-3 text-md text-justify max-w-prose">
				{{ article.excerpt }}
			</p>
		</router-link>
		<div class="flex">
			<div class="flex-none w-3/4">
				<ul class="hashtags">
					<li class="hashtag" v-for="tag in article.tags" :key="tag">
						<router-link
							:to="{ name: 'tag', params: { tag: tag } }"
							class="thick-underline"
						>
							{{ tag }}
						</router-link>
					</li>
				</ul>
			</div>
			<span class="flex-grow"></span>
			<span class="view-count flex-none text-sm font-normal">
				{{ article.viewsCount }}
				<img
					src="./../assets/icons/eye.svg"
					alt="Eye"
					class="h-4 mb-1 inline dark:hidden"
				/>
				<img
					src="./../assets/icons/eye-white.svg"
					alt="Eye"
					class="dark:inline h-4 mb-1 hidden"
				/>
			</span>
		</div>
	</article>
</template>

<script>
export default {
	name: "ArticleBlock",
	props: { article: Object },
};
</script>

<style scoped></style>
