<template>
	<footer class="w-full">
		<div class="mx-3 h-px bg-yellow-800 rounded-none md:mb-12"></div>
		<div class="min-h-24 py-4 md:float-left md:w-2/4">
			<center>
				<router-link to="/about" class="text-lg mt-2 uppercase font-bold">
					About Shaoor
				</router-link>
				<br />
				<router-link to="/privacy" class="text-lg mt-2 uppercase font-bold">
					Privacy Policy
				</router-link>
				<br />
				<router-link to="/tnc" class="text-lg mt-2 uppercase font-bold">
					Terms & Conditions
				</router-link>
				<br />
				<a
					href="mailto:hello@shaoor.tech?subject=Applying as Writer"
					class="text-lg mt-2 uppercase font-bold"
				>
					Write With Us
				</a>
				<br />
				Have a question or suggestions? <br />
				<a
					href="mailto:hello@shaoor.tech"
					class="text-base mt-8 font-mono font-normal"
					>hello@shaoor.tech</a
				>
			</center>
		</div>
		<div class="mx-3 h-px bg-yellow-800 rounded-none md:hidden"></div>
		<center>
			<div class="min-h-24 py-4 md:p-0 md:w-2/4 md:float-right">
				<h4 class="text-sm mt-8 font-mono uppercase font-thin">
					Let's Stay in touch!
				</h4>
				<a href="https://facebook.com/shaoortech" target="_blank">
					<img
						src="./../assets/icons/facebook-logo.svg"
						alt="Facebook Logo"
						class="max-h-16 mt-8"
					/>
				</a>
				<a href="https://twitter.com/shaoortech" target="_blank">
					<img
						src="./../assets/icons/twitter-logo.svg"
						alt="twitter Logo"
						class="max-h-16 mt-6 mb-4"
					/>
				</a>
			</div>
		</center>
		<center>
			<small class="text-sm pb-2 block"> Copyright &copy; 2021 | Shaoor</small>
		</center>
		<span
			class="view-count flex-none text-sm font-normal absolute right-20 mb-12 cursor-pointer"
			@click="topFunction()"
		>
			<img
				src="./../assets/icons/up-arrow.svg"
				alt="up arrow"
				class="h-12 z-50 p-2 dark:hidden block"
			/>
			<img
				src="./../assets/icons/up-arrow-white.svg"
				alt="up arrow"
				class="h-12 z-50 p-2 hidden dark:block"
			/>
		</span>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	methods: {
		// When the user clicks on the button, scroll to the top of the document
		topFunction() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		},
	},
};
</script>

<style scoped></style>
