<template>
	<div class="mx-8 w-auto">
		<header class="flex">
			<router-link to="/">
				<div class="flex-none">
					<img
						src="./../assets/icons/shaoor-header.svg"
						alt="Shaoor Logo"
						class="w-48 dark:hidden block"
					/>
					<img
						src="./../assets/icons/shaoor-header-white.svg"
						alt="Shaoor Logo"
						class="w-48 hidden dark:block"
					/>
				</div>
			</router-link>
			<div class="flex-grow"></div>
			<div class="flex-none">
				<div class="w-14 h-8">
					<!-- <button
							onclick="toggleDarkMode()"
							class="mt-6 md:mt-12 cursor-pointer transition duration-500 ease-in-out transform hover:rotate-12 hover:scale-125"
						>
							<svg
								class="w-12 dark:block hidden"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
								/>
							</svg>
							<svg
								class="w-12 dark:hidden block"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
								/>
							</svg>
						</button> -->
					<!-- TODO: add manual dark mode -->
				</div>
			</div>
			<hr class="mt-2" />
		</header>
	</div>
</template>

<script>
export default {
	name: "Header",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
