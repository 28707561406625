<template>
	<h2 class="text-4xl">
		{{ articleTitle }}
		<span class="text-sm inline">
			by
			<router-link
				:to="{
					name: 'Authors',
					params: { authorUsername: authorUsername, authorName: authorName },
				}"
				class="author thick-underline"
				>{{ authorName }}</router-link
			>
		</span>
	</h2>
</template>

<script>
export default {
	name: "TheArticleTitle",
	props: { articleTitle: String, authorUsername: String, authorName: String },
};
</script>

<style scoped></style>
